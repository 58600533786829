import { Route, Redirect } from "react-router-dom";
import Spreadsheets from "./spreadsheets_EmployerHome";
import React, { useState, useEffect, useRef } from "react";
import CurrentWeek from "./CurrentWeekHome";
import axios from "axios";
import { getSunday } from "../../Employee/Calendar";

function App2(props) {
  const [info, setInfo] = useState("");
  const array = useRef(null);
  const index = useRef(null);
  let user = props.user;
  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  let array_f = [];
  for (let i = 0; i < info.length; i++) {
    if (info[i].email === user.email) {
      index.current = i;
      let keys = Object.values(info[i]);
      array.current = keys.splice(8, keys.length);
      console.log("hi");

      for (let i = 0; i < array.current.length; i++) {
        if (array.current[i] !== "x") {
          array_f = [...array_f, array.current[i]];
        }
      }
    }
  }

  let ii;
  array.current = array_f;
  array.current && (ii = array.current.length);

  let list = [];

  const firstDay = getSunday(new Date(user.date));
  const firstDay1 = firstDay.getDate() - Math.floor(firstDay.getDate() / 7) * 7;

  const extra = Math.floor(
    (new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay1) -
      new Date(2022, 3, 3)) /
      1000 /
      60 /
      60 /
      24 /
      7
  );

  const minDate = new Date(2022, 3, 3);
  const maxDate = new Date(2032, 3, 4);
  let duration = Math.floor((maxDate - minDate) / 1000 / 60 / 60 / 24 / 7);
  for (let i = extra; i < duration + extra; i++) {
    let part = array.current && (
      <Route path={`/employerX/Home/${i}`} key={i}>
        <Spreadsheets n={i - extra} pr={ii} user={user} />
      </Route>
    );
    list.push(part);
  }
  return (
    <div>
      <div>
        {list.map((l) => {
          return l;
        })}
      </div>
      <Redirect
        from={`/employerX/Home`}
        exact
        to={`/employerX/Home/${CurrentWeek()}`}
      />
    </div>
  );
}

export default App2;

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import "./EmployerX.css";
import Navbar from "./Navbar";
import { io } from "socket.io-client";

export let obj1 = {};
export let obj2 = {};

const Employees = (props) => {
  const [projectnew, setProjectNew] = useState("");
  const [info, setInfo] = useState("");
  const [socket, setSocket] = useState(null);
  const [socket1, setSocket1] = useState(null);
  let user = props.user;

  useEffect(() => {
    setSocket(io("http://localhost:5000"));
  }, []);

  useEffect(() => {
    setSocket1(io("http://localhost:5001"));
  }, []);

  useEffect(() => {
    !!socket && socket.emit("newUser", user.email);
  }, [socket, user.email]);

  const Add_project = (e) => {
    e.preventDefault();
    const blog = {
      name: projectnew,
    };
    fetch("https://dev-timesheet-back.biznetx.org/api/projects/", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(blog),
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 180);
  };

  const getAll = useCallback(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  useEffect(() => {
    getAll();
  }, []);

  let history = useHistory();

  const handleChangeEmployee = (e) => {
    for (var i = 0; i < info.length; i++) {
      if (e === info[i].name && e != "") {
        history.push(`/employerX/${i}`);
      }
    }
  };

  const handleChangeEmployeeInfo = (e) => {
    for (var i = 0; i < info.length; i++) {
      if (e === info[i].name && e != "") {
        history.push(`/employerX/info${i}`);
      }
    }
  };

  return (
    <div className="Employer">
      <div>
        <div className="dropdownX">
          <a href="/employerX/Home">Home</a>
        </div>
      </div>
      <div>
        <div className="dropdownX">
          <a href="/employerX/spreadsheets">Spreadsheets</a>
        </div>
      </div>
      <div>
        <a href="#" onClick={() => (obj1.x = true)}>
          Add an employee
        </a>
      </div>
      <div>
        <a href="#" onClick={() => (obj2.x = true)}>
          Update an employee
        </a>
      </div>
      <div>
        <div className="dropdownX">
          <a href="#">Employees</a>
          <div className="contents">
            <select onChange={(e) => handleChangeEmployee(e.target.value)}>
              {!!info && info.map((option) => <option>{option.name}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div>
        <div className="dropdownX">
          <a href="#">Employees Info</a>
          <div className="contents">
            <select onChange={(e) => handleChangeEmployeeInfo(e.target.value)}>
              {!!info && info.map((option) => <option>{option.name}</option>)}
            </select>
          </div>
        </div>
      </div>
      <div>
        <div className="dropdownX">
          <a href="/employerX/projects">Projects</a>
        </div>
      </div>
      <div>
        <div className="dropdownX">
          <a href="#">Add a project</a>
          <div className="contents">
            <input
              placeholder="New Project"
              type="text"
              onChange={(e) => setProjectNew(e.target.value)}
            ></input>
            <button onClick={Add_project}>Add</button>
          </div>
        </div>
      </div>
      {!!socket && <Navbar socket={socket} />}
    </div>
  );
};

export default Employees;

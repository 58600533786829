import React from "react";
import { Route, Switch } from "react-router-dom";
import "./EmployerX.css";
import Employee from "../Employee/Employee";
import ProjectsW from "./ProjectsW";
import Page from "./Page";
import EmployeesW from "./EmployeesW";
import HomeW from "./HomeW";
import InfoW from "./InfoW";
import AddAnEmployee from "./AddAnEmployee";
import UpdateAnEmployee from "./UpdateAnEmployee";
import auth from "../../services/authService";

let user = auth.getCurrentUser();

const EmployerX = () => {
  return (
    <div>
      <Page user={user} />
      <InfoW />
      <EmployeesW />
      <AddAnEmployee />
      <UpdateAnEmployee />
      <Switch>
        <Route path="/employerX/Home" component={HomeW} />
        <Route path="/employerX/projects" component={ProjectsW} />
        <Route path="/employerX/spreadsheets" component={Employee} />
      </Switch>
    </div>
  );
};

export default EmployerX;

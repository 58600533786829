import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route } from "react-router-dom";
import Fullspreadsheets_Employer from "./Fullspreadsheets";

const EmployeeData = () => {
  const [info, setInfo] = useState("");
  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  let list = [];
  for (let n = 0; n < info.length; n++) {
    let part = (
      <div>
        <Route path={`/employerX/${n}`} key={n}>
          <Fullspreadsheets_Employer user={info[n]} />
        </Route>
      </div>
    );
    list.push(part);
  }

  return (
    <div>
      {list.map((l) => {
        return l;
      })}
    </div>
  );
};

export default EmployeeData;

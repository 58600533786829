import React from "react";
import Calendar_Employer from "./Calendar_EmployerHome";

export default function Weekdays(props) {
  let user = props.user;

  return (
    <div className="Menu">
      <ul>
        <li>
          <a href="#">Calendar</a>
          <div className="Menu-1">
            <Calendar_Employer user={user} />
          </div>
        </li>
      </ul>
    </div>
  );
}

import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Spreadsheets from "./Spreadsheets";
import React, { useState, useEffect, useRef } from "react";
import Weekdays_Employer from "./Weekdays";
import CurrentWeek from "./CurrentWeek";
import axios from "axios";

function App2(props) {
  const [info, setInfo] = useState("");
  const array = useRef(null);
  const index = useRef(null);
  let user = props.user;

  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  let array_f = [];
  for (let i = 0; i < info.length; i++) {
    if (info[i].email === user.email) {
      index.current = i;
      let keys = Object.values(info[i]);
      array.current = keys.splice(8, keys.length);

      for (let i = 0; i < array.current.length; i++) {
        if (array.current[i] !== "x") {
          array_f = [...array_f, array.current[i]];
        }
      }
    }
  }
  let ii;
  array.current = array_f;
  array.current && (ii = array.current.length);

  let list = [];

  const minDate = new Date(2022, 3, 4);
  const maxDate = new Date(2032, 3, 4);
  let duration = Math.floor((maxDate - minDate) / 1000 / 60 / 60 / 24 / 7);

  for (let i = 0; i < duration; i++) {
    let part = array.current && (
      <Route path={`/employerX/${index.current}/${i}`} key={i}>
        <Spreadsheets n={i} pr={ii} user={user} />
      </Route>
    );
    list.push(part);
  }

  return (
    <BrowserRouter>
      <Weekdays_Employer user={user} />
      <div className="spreadsheets">
        <Switch>
          {list.map((l) => {
            return l;
          })}
          <Redirect
            from={`/employerX/${index.current}`}
            exact
            to={`/employerX/${index.current}/${CurrentWeek()}`}
          />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App2;

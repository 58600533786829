import React, { useState, useEffect } from "react";
import axios from "axios";
import { Route } from "react-router-dom";
import Fullspreadsheets_Employer from "./Home/Fullspreadsheets_EmployerHome";
import Weekdays_Employer from "./Home/Weekdays_EmployerHome";
import auth from "../../services/authService";

let user = auth.getCurrentUser();

const EmployeeData = () => {
  const [info, setInfo] = useState("");
  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  let tables = [];
  for (let n = 1; n < info.length; n++) {
    if (info[n].employer === user.email) {
      let list = (
        <div className="tables">
          {info[n].name}
          <div className="temporary">
            <Route path={`/employerX/Home`} key={n}>
              <Fullspreadsheets_Employer user={info[n]} />
            </Route>
          </div>
        </div>
      );
      tables.push(list);
    }
  }

  return (
    <div>
      <Weekdays_Employer user={!!info && info[0]} />
      <div>
        {tables.map((l) => {
          return l;
        })}
      </div>
    </div>
  );
};

export default EmployeeData;

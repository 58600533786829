import React, { useState, useEffect } from "react";
import Calendar from "./Calendar";
import axios from "axios";
import { io } from "socket.io-client";
import Navbar from "./Navbar";

function CurrentDate() {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  return <h4>The current date is {date}</h4>;
}

export default function Weekdays(props) {
  const [info, setInfo] = useState("");
  const [socket, setSocket] = useState(null);
  const [socket1, setSocket1] = useState(null);
  const [info1, setInfo1] = useState("");

  let user = props.user;

  useEffect(() => {
    setSocket(io("http://localhost:5000"));
  }, []);

  useEffect(() => {
    setSocket1(io("http://localhost:5001"));
  }, []);

  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employers")
      .then((response) => {
        const data = response.data;
        setInfo1(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  const handleNotification = () => {
    socket.emit("sendNotification", {
      senderName: user.email,
      receiverName: info1[0].email,
      shown: false,
    });
  };

  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  return (
    <div className="menu">
      <div className="date11">{CurrentDate()}</div>
      <ul>
        <li>
          <a href="#">Calendar</a>
          <div className="menu-1">
            <Calendar />
          </div>
        </li>
      </ul>
      <div>{!!socket1 && <Navbar socket={socket1} />}</div>
      <button className="button" onClick={() => handleNotification()}>
        Submit
      </button>
    </div>
  );
}

import React, { useState } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import auth from "../../services/authService";

let user = auth.getCurrentUser();

export function getSunday(d) {
  d = new Date(d);
  let diff = d.getDate() - d.getDay();
  return new Date(d.setDate(diff));
}

const ReactCalendar = () => {
  const [date, setDate] = useState(new Date());
  const firstDay = getSunday(new Date(user.date));
  const firstDay1 = firstDay.getDate() - Math.floor(firstDay.getDate() / 7) * 7;
  const extra = Math.floor(
    (new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay1) -
      new Date(2022, 3, 3)) /
      1000 /
      60 /
      60 /
      24 /
      7
  );
  const maxDate = new Date(2032, 3, 4);
  let duration = Math.floor(
    (date - new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay1)) /
      1000 /
      60 /
      60 /
      24 /
      7
  );

  const onChange = (date) => {
    setDate(date);
  };

  return (
    <Link to={`/employerX/spreadsheets/${duration + extra}`}>
      <Calendar
        onChange={onChange}
        value={date}
        minDate={
          new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay1)
        }
        maxDate={maxDate}
      />
    </Link>
  );
};

export default ReactCalendar;

import React, { useState, useRef, useEffect } from "react";
import Calendar from "react-calendar";
import { Link } from "react-router-dom";
import axios from "axios";

const ReactCalendar = (props) => {
  const [date, setDate] = useState(new Date());

  const minDate = new Date(2022, 3, 4);
  const maxDate = new Date(2032, 3, 4);
  console.log(minDate);
  let duration = Math.floor((date - minDate) / 1000 / 60 / 60 / 24 / 7);

  const [info, setInfo] = useState("");
  const array = useRef(null);
  const index = useRef(null);
  let user = props.user;

  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  for (let i = 0; i < info.length; i++) {
    if (info[i].email === user.email) {
      index.current = i;
      let keys = Object.values(info[i]);
      array.current = keys.splice(8, keys.length);
    }
  }

  const onChange = (date) => {
    setDate(date);
  };

  return (
    <Link to={`/employerX/${index.current}/${duration}`}>
      <Calendar
        onChange={onChange}
        value={date}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Link>
  );
};

export default ReactCalendar;

import React from "react";
import Calendar_Employer from "./Calendar";

function CurrentDate() {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  return (
    <div>
      <h6>The current date is {date}</h6>
    </div>
  );
}

export default function Weekdays(props) {
  let user = props.user;

  return (
    <div className="Menu">
      <h6>{CurrentDate()}</h6>
      <ul>
        <li>
          <a href="#">Calendar</a>
          <div className="Menu-1">
            <Calendar_Employer user={user} />
          </div>
        </li>
      </ul>
    </div>
  );
}

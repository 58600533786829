import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Spreadsheets from "./Spreadsheets";
import React, { useState, useEffect, useRef } from "react";
import Weekdays from "./Weekdays";
import CurrentWeek from "./CurrentWeek";
import axios from "axios";
import { getSunday } from "./Calendar";

function App2(props) {
  const [info, setInfo] = useState("");
  let user = props.user;

  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  let obj = { x: 0 };
  for (let i = 0; i < info.length; i++) {
    if (info[i].email === user.email) {
      let keys = Object.values(info[i]);
      for (let i = 8; i < keys.length; i++) {
        if (keys[i] !== "x") {
          obj.x = obj.x + 1;
        }
      }
    }
  }

  let list = [];

  const firstDay = getSunday(new Date(user.date));
  const firstDay1 = firstDay.getDate() - Math.floor(firstDay.getDate() / 7) * 7;
  const minDate = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay1
  );
  const maxDate = new Date(2032, 3, 4);
  let duration = Math.floor((maxDate - minDate) / 1000 / 60 / 60 / 24 / 7);

  const extra = Math.floor(
    (new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay1) -
      new Date(2022, 3, 3)) /
      1000 /
      60 /
      60 /
      24 /
      7
  );

  for (let i = extra; i < duration + extra; i++) {
    let part = (
      <Route path={`/employerX/spreadsheets/${i}`} key={i}>
        <Spreadsheets n={i - extra} pr={obj.x} user={user} />
      </Route>
    );
    list.push(part);
  }

  return obj.x ? (
    <BrowserRouter>
      <Weekdays user={user} />
      <div className="datas">
        <Switch>
          {list.map((l) => {
            return l;
          })}
          <Redirect
            from="/employerX/spreadsheets"
            exact
            to={`/employerX/spreadsheets/${CurrentWeek()}`}
          />
        </Switch>
      </div>
    </BrowserRouter>
  ) : (
    !!info && <h1>Your employer hasn't assigned you any projects yet.</h1>
  );
}

export default App2;

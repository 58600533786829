import React, { useState, useEffect, useCallback } from "react";
import Spreadsheet from "react-spreadsheet";
import axios from "axios";
import { saveSpreadsheet } from "./spreadsheetServiceHome";

const Spreadsheets = (props) => {
  let n = props.n;
  let pr = props.pr;
  let user = props.user;
  const [posts, setPosts] = useState();

  const getBlogPost = useCallback(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/spreadsheets")
      .then((response) => {
        const data = response.data;
        const data_per_user = [];

        for (let i = 0; i < data.length; i++) {
          if (!!data[i].email && data[i].email === user.email) {
            data_per_user.push(data[i]);
          }
        }

        if (
          Array.isArray(data_per_user) &&
          data_per_user.length >= 7 * 5 * (n + 1)
        ) {
          setPosts(data_per_user);
        }
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, [n]);

  useEffect(() => {
    getBlogPost();
  }, [getBlogPost, n]);

  const displayBlogPost = useCallback(() => {
    let list = [];
    let mini_list = [];
    let extra_list = [];
    let sum_column = 0;
    let sum_row = 0;
    if (Array.isArray(posts)) {
      for (let j = 0; j < pr; j++) {
        mini_list = [];
        for (let i = 0; i < 7; i++) {
          mini_list.push({ value: posts[7 * 5 * n + i + 7 * j].value });
        }
        for (let i = 0; i < 7; i++) {
          sum_column += parseInt(posts[7 * 5 * n + i + 7 * j].value);
        }
        mini_list.push({ value: sum_column });
        list.push(mini_list);
        sum_column = 0;
      }
    }
    for (let j = 0; j < 7; j++) {
      for (let i = 0; i < pr; i++) {
        sum_row += parseInt(posts[7 * 5 * n + j + 7 * i].value);
      }
      extra_list.push({ value: sum_row });
      sum_row = 0;
    }
    let k = 0;
    for (let j = 0; j < 7; j++) {
      k += extra_list[j].value;
    }
    extra_list.push({ value: k });
    list.push(extra_list);
    return list.slice(list.length - 1, list.length);
  }, [posts]);
  return (
    <div>
      {!!posts && (
        <Spreadsheet
          readOnly={true}
          data={displayBlogPost()}
          onBlur={getBlogPost}
          rowLabels={["Total"]}
          columnLabels={[
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat",
            "Total",
          ]}
        />
      )}
    </div>
  );
};

export default Spreadsheets;

import { getSunday } from "./Calendar";
import auth from "../../services/authService";

let user = auth.getCurrentUser();

export default function CurrentWeek() {
  const current = new Date();
  const firstDay = getSunday(new Date(user.date));
  const firstDay1 = firstDay.getDate() - Math.floor(firstDay.getDate() / 7) * 7;
  const minDate = new Date(
    firstDay.getFullYear(),
    firstDay.getMonth(),
    firstDay1
  );
  let duration = Math.floor((current - minDate) / 1000 / 60 / 60 / 24 / 7);
  return duration;
}

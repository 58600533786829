import React, { useState, useEffect } from "react";
import axios from "axios";

const Employer_projects = (props) => {
  const [info, setInfo] = useState("");
  let n = props.n;
  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  return (
    <div>
      {!!info && (
        <div>
          <ul className="projects">
            <li>Name: {info[n].name}</li>
            <li>Email: {info[n].email}</li>
            <li>Specialty: {info[n].specialty}</li>
            <li>
              Salary rate: {info[n].salaryrate}
              {info[n].currency}
            </li>
            {info[n].project1 != "x" && <li>Projects: {info[n].project1}</li>}
            {info[n].project2 != "x" && <li>Projects: {info[n].project2}</li>}
            {info[n].project3 != "x" && <li>Projects: {info[n].project3}</li>}
            {info[n].project4 != "x" && <li>Projects: {info[n].project4}</li>}
            {info[n].project5 != "x" && <li>Projects: {info[n].project5}</li>}
            <li>ID: {info[n]._id}</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default Employer_projects;

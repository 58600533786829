import "./Weekdays.css";
import auth from "../../services/authService";
import Fullspreadsheets from "./Fullspreadsheets";
import React from "react";

let user = auth.getCurrentUser();

const Employee = () => {
  return <Fullspreadsheets user={user} />;
};

export default Employee;

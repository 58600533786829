import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { obj2 } from "./Page";
import { io } from "socket.io-client";
import auth from "../../services/authService";

let user = auth.getCurrentUser();

const options = [
  { value: "USD", label: "USD" },
  { value: "GEL", label: "GEL" },
  { value: "EURO", label: "EURO" },
  { value: "RUB", label: "RUB" },
];

const Employer = () => {
  const [buttonPopup, setButtonPopup] = useState(false);
  const [info, setInfo] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [salaryrate, setSalaryRate] = useState("");
  const [currency, setCurrency] = useState({ value: "USD", label: "USD" });
  const [project, setProject] = useState("");
  const [topic, setTopic] = useState({ value: "project1", label: "project1" });
  const [date, setDate] = useState("");
  const [project1, setProject1] = useState("x");
  const [project2, setProject2] = useState("x");
  const [project3, setProject3] = useState("x");
  const [project4, setProject4] = useState("x");
  const [project5, setProject5] = useState("x");
  const [_id, setId] = useState("");
  const [info1, setInfo1] = useState("");
  const [socket1, setSocket1] = useState(null);

  useEffect(() => {
    setSocket1(io("http://localhost:5001"));
  }, []);

  useEffect(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employers")
      .then((response) => {
        const data = response.data;
        setInfo1(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  const getTopics = useCallback(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/projects")
      .then((response) => {
        const data = response.data;
        setTopic(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  useEffect(() => {
    getTopics();
  }, []);

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    const currency_value = currency.value;
    const blog = {
      name,
      email,
      specialty,
      salaryrate: parseInt(salaryrate),
      currency: currency_value,
      employer: user.email,
      date,
      project1,
      project2,
      project3,
      project4,
      project5,
    };

    fetch(`https://dev-timesheet-back.biznetx.org/api/employees/${_id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(blog),
    });

    setTimeout(() => {
      window.location.reload(false);
    }, 180);

    socket1.emit("sendNotification", {
      senderName: user.email,
      receiverName: email,
      shown: false,
    });
  };

  const addProject = (par) => {
    par <= 5 &&
      setProject((prev) => [
        ...prev,
        <select
          value={topic.value}
          onChange={(e) => eval(`setProject${par}`)(e.target.value)}
        >
          {topic.map((topic) => (
            <option value={topic.name}>{topic.name}</option>
          ))}
        </select>,
      ]);
  };

  const removeProject = () => {
    setProject((prev) => prev.slice(0, -1));
  };

  const handleChange = (e) => {
    setCurrency({ value: e.target.value, label: e.target.value });
  };

  const getAll = useCallback(() => {
    axios
      .get("https://dev-timesheet-back.biznetx.org/api/employees")
      .then((response) => {
        const data = response.data;
        setInfo(data);
      })
      .catch(() => {
        alert("Error retrieving data!!!");
      });
  }, []);

  useEffect(() => {
    getAll();
  }, []);

  const handleChangeEmployee = (e) => {
    for (var i = 0; i < info.length; i++) {
      if (e === info[i].name && e != "") {
        setName(info[i].name);
        setEmail(info[i].email);
        setId(info[i]._id);
        setSpecialty(info[i].specialty);
        setSalaryRate(info[i].salaryrate);
        setSalaryRate(info[i].date);
      }
    }
  };

  return (
    <div className="dropdownX">
      {obj2.x ? (
        <div className="popup">
          <div className="popup-inner">
            <button
              className="close-btn"
              onClick={() => {
                setButtonPopup(!buttonPopup); // this is just for triggering a change for the component to render
                obj2.x = false;
              }}
            >
              close
            </button>
            <div className="sign">
              <div className="sign-form">
                <div className="wrapper">
                  <input
                    placeholder="Employee Name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></input>
                  <input
                    placeholder="Employee Email"
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></input>
                  <input
                    placeholder="Employee id"
                    type="text"
                    value={_id}
                    onChange={(e) => setId(e.target.value)}
                  ></input>
                  <input
                    placeholder="Specialty"
                    type="text"
                    value={specialty}
                    onChange={(e) => setSpecialty(e.target.value)}
                  ></input>
                  <input
                    placeholder="Salary Rate"
                    type="number"
                    value={salaryrate}
                    onChange={(e) => setSalaryRate(e.target.value)}
                  ></input>
                  <div className="flex2">
                    <select
                      onChange={(e) => handleChangeEmployee(e.target.value)}
                    >
                      {!!info &&
                        info.map((option) => <option>{option.name}</option>)}
                    </select>
                    <select value={currency.value} onChange={handleChange}>
                      {options.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    {project}
                    <button
                      className="signIn2"
                      onClick={() => addProject(project.length + 1)}
                    >
                      Add a project
                    </button>
                    <button className="signIn2" onClick={removeProject}>
                      Remove a project
                    </button>
                    <button
                      className="signIn2"
                      onClick={_id !== "" && handleSubmitUpdate}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Employer;

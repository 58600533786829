import http from "./httpService";

const apiEndpoint = "https://dev-timesheet-back.biznetx.org/api/employers";

export function register(employer) {
  return http.post(apiEndpoint, {
    email: employer.email,
    password: employer.password,
    passwordRepeat: employer.passwordRepeat,
    name: employer.name,
    surname: employer.surname,
    companyName: employer.companyName,
    companyID: employer.companyID,
    companyAddress: employer.companyAddress,
    passportID: employer.passportID,
    billing: employer.billing,
    address: employer.address,
    date: employer.date,
    isAdmin: employer.isAdmin,
  });
}

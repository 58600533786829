import React from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import auth from "../services/authService";
import gb from "../Images/gb.svg";
import LogoDark from "../Images/logo.png";

const Header = () => {
  const user = auth.getCurrentUser();
  return (
    <div className="Header">
      <div className="Header1">
        <img src={LogoDark} alt="logo" />
      </div>
      <div className="Header-right">
        {user && (
          <React.Fragment>
            <NavLink to="/logout" className="logout">
              Logout
            </NavLink>
          </React.Fragment>
        )}
        <div className="Header2">
          EN
          <img src={gb} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
